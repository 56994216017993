import React, { ReactElement, ChangeEvent } from 'react'
import { FormControl, InputLabel, NativeSelect, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { AppUser } from '../../models/app_user'

const useStyles = makeStyles((theme: Theme) => ({
  monthFilterSection: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    flexWrap: 'wrap',
  },
  formControlLeft: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    flexGrow: 1,
  },
  formControlUsers: {
    flexBasis: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  select: {
    '&>select': {
      background: theme.palette.action.hover,
      borderRadius: '5px',
      padding: theme.spacing(2),
    },
    '&::before': {
      borderBottom: 'white',
    },
  },
  label: {
    top: theme.spacing(-2),
    fontSize: '1.2rem',
  },
}))

type PaymentListProps = {
  selectedMonth: number
  selectedYear: number
  selectedUser: number
  users: {
    [key: number]: AppUser
  }
  setSelectedMonth(month: number): void
  setSelectedYear(year: number): void
  setSelectedUser(user: number): void
}
const months = [
  { value: 0, name: 'January' },
  { value: 1, name: 'February' },
  { value: 2, name: 'March' },
  { value: 3, name: 'April' },
  { value: 4, name: 'May' },
  { value: 5, name: 'June' },
  { value: 6, name: 'July' },
  { value: 7, name: 'August' },
  { value: 8, name: 'September' },
  { value: 9, name: 'October' },
  { value: 10, name: 'November' },
  { value: 11, name: 'December' },
]
export default function PaymentFilter({ selectedMonth, setSelectedMonth, selectedYear, setSelectedYear, users, selectedUser, setSelectedUser }: PaymentListProps): ReactElement {
  const classes = useStyles()
  return (
    <section className={classes.monthFilterSection}>
      <FormControl className={clsx([classes.formControl, classes.formControlLeft])}>
        <InputLabel htmlFor="month-native-label-placeholder" className={classes.label}>
          Month
        </InputLabel>
        <NativeSelect
          value={selectedMonth}
          className={classes.select}
          onChange={(e: ChangeEvent<HTMLSelectElement>): void => setSelectedMonth(+e.target.value)}
          inputProps={{
            name: 'month',
            id: 'month-native-label-placeholder',
          }}
        >
          {months.map((month) => (<option key={month.value} value={month.value}>{month.name}</option>))}
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="month-native-label-placeholder" className={classes.label}>
          Year
        </InputLabel>
        <NativeSelect
          value={selectedYear}
          className={classes.select}
          onChange={(e: ChangeEvent<HTMLSelectElement>): void => setSelectedYear(+e.target.value)}
          inputProps={{
            name: 'month',
            id: 'month-native-label-placeholder',
          }}
        >
          {[2018, 2019, 2020, 2021, 2022, 2023].map((year) => (<option key={year} value={year}>{year}</option>))}
        </NativeSelect>
      </FormControl>
      {users && (
        <>
          <FormControl className={clsx([classes.formControl, classes.formControlUsers])}>
            <InputLabel shrink htmlFor="user-native-label-placeholder">
              User
            </InputLabel>
            <NativeSelect
              value={selectedUser}
              onChange={(e: ChangeEvent<HTMLSelectElement>): void => setSelectedUser(+e.target.value)}
              inputProps={{
                name: 'user',
                id: 'user-native-label-placeholder',
              }}
            >
              {Object.keys(users).map((id) => (<option key={id} value={users[id].id}>{users[id].name}</option>))}
            </NativeSelect>
          </FormControl>
        </>
      )}
    </section>

  )
}
